import React from "react";
import TipeeeButton from "./TipeeeButton";
import { useTranslation } from "react-i18next";

const FEATURES = [
  {
    image: require("../assets/images/Home 1.png"),
    title: "feature1Header",
    description: "feature1Description",
  },
  {
    image: require("../assets/images/Home 1-1.png"),
    title: "feature2Header",
    description: "feature2Description",
  },
  {
    image: require("../assets/images/Home 1-2.png"),
    title: "feature3Header",
    description: "feature3Description",
  },
];

export default function Features() {
  const { t } = useTranslation();

  return (
    <span>
      <section id="about" className="">
        <div className="container">
          <p className="fs-1 fw-bold text-center">{t("tipeeeHeader")}</p>
          <p className="mt-5 mx-auto text-center text-container">{t("tipeeeDescription")}</p>
          <span style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <TipeeeButton />
          </span>
          
        </div>
      </section>
    
    <section id="tipeee" className="">
      
    </section>
    <section id="features" className="">
      <div className="container">
        
        <p className="fs-1 fw-bold text-center">{t("featuresHeader")}</p>

        <div className="row">
          <div className="col-sm-10 mx-auto">
            {FEATURES.map((feature, index) => (
              <div
                className={`row text-center text-md-start my-4 my-ms-5 py-ms-5 ${
                  index % 2 && "flex-row-reverse"
                }`}
                key={index}
              >
                <div className="col-md-4">
                  <img
                    className="img-fluid d-block mx-auto"
                    src={feature.image}
                    alt="feature"
                  />
                </div>
                <div className="col-md-8">
                  <div style={{ marginTop: "5vw" }} className="text-container">
                    <h3 className="">{t(feature.title)}</h3>
                    <p className="">{t(feature.description)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      </section>
    </span>
  );
}
