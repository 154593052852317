import {useEffect, useState} from "react";
import i18next from "i18next";
import {availableLanguages} from "../i18nextConf";

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18next.language || "fr-FR"
  );
  const [selectedLanguageObject, setSelectedLanguageObject] = useState(
    availableLanguages[0]
  );

  useEffect(() => {
    const s = availableLanguages.find(
      (lang) => lang.code === selectedLanguage
    );
    setSelectedLanguageObject(s);
  }, [selectedLanguage]);


  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
      }}
    >
      <>
        <div
          id="language-selector"
          className="container d-flex justify-content-end"
        >
          <div className="dropdown">
            <button
              className="btn btn-light btn-sm dropdown-toggle "
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                borderTopLeftRadius: "0",
                borderTopRightRadius: "0",
                minWidth: "145px",
              }}
            >
              {selectedLanguageObject?.name} {selectedLanguageObject?.flag}
            </button>
            <ul className="dropdown-menu">
              {availableLanguages.map((locale) => {
                const isSelected = locale.code === selectedLanguage;

                return (
                  <li key={locale.code}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      className={`cursor-pointer dropdown-item d-flex justify-content-between align-items-center ${
                        isSelected && "bg-info text-light"
                      }`}
                      onClick={() => {
                        setSelectedLanguage(locale.code);
                        i18next.changeLanguage(locale.code);
                      }}
                    >
                      {locale.name} {locale.flag}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </>
    </div>
  );
}


export default LanguageSelector;