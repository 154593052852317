import React from "react";
import Sidebar from "../components/Sidebar";
import CreateQuizFab from "../components/CreateQuizFab";

export default function SideBarLayout({children}: {children: React.ReactNode}) {
  return (
    <div className="dashboard d-flex align-items-start">
      <Sidebar/>

      <main className=" flex-grow-1 w-100">
        <div className="content">
          <CreateQuizFab />


          {children}
        </div>
      </main>
    </div>
  );
}