import React, { useContext, useEffect, useState } from 'react';
import ContentLoading from '../components/ContentLoading';
import { UserContext } from '../contexts/UserContext';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { passwordRegex } from '../utils/constants';
import { getSingleDoc, registerWithEmailAndPassword } from '../firebase';

export const Register = () => {
	const { login, user } = useContext(UserContext);
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [initialLoading, setInitialLoading] = useState(true);

	useEffect(() => {
		setInitialLoading(false);
	}, []);

	if (initialLoading) {
		return <></>;
	}

	if (user.isAdmin) {
		return <Navigate to='/dashboard' replace />;
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		setError(''); // reset error

		if (password !== confirmPassword) {
			setError(t('errors.matchPassword'));
			return;
		}

		if (!password.match(passwordRegex)) {
			setError(t('errors.matchPasswordRegex'));
			return;
		}

		setLoading(true);

		const name = email?.split('@')[0] || '';
		registerWithEmailAndPassword(name, email, password)
			.then(async (res) => {
				const userData = await getSingleDoc('players', res.user.uid);
				login(userData);

				navigate('/dashboard');
			})
			.catch((err) => {
				setError(t(i18n.exists(err) ? err : 'globalError'));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div
			className='container'
			style={{
				marginTop: '180px',
			}}
		>
			<div className='d-flex align-items-center justify-content-center flex-column h-100 w-100'>
				<h5 className='card-title text-center text-capitalize mb-4'>
					{t('register')}
				</h5>
				<div className='card'>
					{loading && <ContentLoading />}
					<div
						className='card-body'
						style={{
							width: '400px',
						}}
					>
						<form onSubmit={handleSubmit}>
							<div className='mb-3'>
								<label htmlFor='email' className='form-label'>
									{t('emailAddress')}
								</label>
								<input
									type='email'
									className='form-control'
									id='email'
									value={email}
									placeholder={t('emailAddress')}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>

							<div className='mb-3'>
								<label htmlFor='password' className='form-label'>
									{t('password')}
								</label>
								<input
									type='password'
									className='form-control'
									id='password'
									placeholder={t('password')}
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>

							<div className='mb-3'>
								<label htmlFor='confirmPassword' className='form-label'>
									{t('confirmPassword')}
								</label>
								<input
									type='password'
									className='form-control'
									id='confirmPassword'
									placeholder={t('confirmPassword')}
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
								/>
							</div>
							<p className='small opacity-50'>{t('passwordSnippet')}</p>

							<div className=''>
								<button
									disabled={!(password && confirmPassword && email)}
									type='submit'
									className='btn btn-dark w-100'
								>
									{t('register')}
								</button>
							</div>

							{error && (
								<div className='alert alert-danger mt-3 mb-1 small py-2'>
									<i className='bi bi-exclamation-diamond me-2' />
									{error}
								</div>
							)}
						</form>
					</div>
				</div>

				<NavLink
					to='/login'
					className='btn btn-link text-dark text-decoration-none'
				>
					<>
						{t('alreadyHaveAnAccount')}? {t('login')}
					</>
				</NavLink>
			</div>
		</div>
	);
};
