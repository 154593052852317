import { NavLink, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";
import { adminRoutes, userRoutes } from "../routes/routes";
import { useTranslation } from "react-i18next";
import { getUniqueArrayBy } from "../utils/helpers";

const Links = getUniqueArrayBy([...adminRoutes, ...userRoutes], "path").filter(
  (link) => link.sidebar
);

const Sidebar = () => {
  const { user, logout } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="sidebar d-flex flex-column">
      <div className="sidebar-header">
        <h3 className="mb-4">
          {user.role === "admin" && t("sidebar.title.admin")}
        </h3>
      </div>
      <div className="sidebar-body flex-grow-1 d-flex flex-column justify-content-between">
        <ul className="list-unstyled ">
          {Links.map(
            ({ icon, i18n, path, allowedRoles }, index) =>
              allowedRoles.includes(user.role) && (
                <li key={index} className="mb-2">
                  <NavLink
                    end
                    className={({ isActive }) =>
                      `btn btn-white w-100 text-start ${
                        isActive ? "btn-dark" : undefined
                      }`
                    }
                    to={path}
                  >
                    <>
                      {icon}
                      {t(i18n)}
                    </>
                  </NavLink>
                </li>
              )
          )}
        </ul>

        <div className="d-flex flex-column gap-3">
          <button
            className={`btn btn-secondary text-light w-100`}
            onClick={() => {
              navigate("/");
            }}
          >
            <i className="bi bi-house-door me-2" />
            {t("home")}
          </button>

          <button
            className={`btn btn-danger text-light w-100`}
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            <i className="bi bi-box-arrow-left me-2" />
            {t("logout")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
