import React from "react";
import { useTranslation } from "react-i18next";

import arrow from "../assets/images/ios-arrow-left-svgrepo-com.svg";
import Navbar from "./Navbar";
import AppStoreButton from "./AppStoreButton";
import PlayStoreButton from "./PlayStoreButton";

export default function Hero() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        backgroundImage: `url(${require("../assets/images/header-bg.png")})`,
        minHeight: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="d-flex flex-column position-relative"
    >
      <div
        style={{
          height: "40px",
        }}
      />

      <Navbar />

      <div
        style={{
          height: "60px",
        }}
      />

      <div className="container w-75 flex-grow-1 d-flex flex-column align-items-center justify-content-center">
        <h1 className="text-center mt-auto">{t("heroText")}</h1>

        <div className="rowToCol" style={{
          width: "50%",
          padding: "20px"
        }}>
          <AppStoreButton />
          <PlayStoreButton />
        </div>
        
        <a
          className="btn btn-light rounded-circle mt-auto mb-5"
          style={{
            // position: "absolute",
            // bottom: "30px",
            // left: "calc(50% - 25px)",
            width: "50px",
            height: "50px",
          }}
          href="#about"
        >
          <img
            src={arrow}
            alt="down"
            style={{
              transform: "rotate(-90deg)",
            }}
          />
        </a>
      </div>
    </div>
  );
}
