import React from "react";
import { NAVBAR_LINKS } from "../utils/constants";
import logo from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  const { t } = useTranslation();

  return (
    <nav id="navbar_top" className="navbar navbar-expand-md navbar-light ">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <a className="navbar-brand" href="/#">
          <img height="80px" src={logo} alt="logo" />
        </a>
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            {NAVBAR_LINKS.map((link, index) => (
              <li
                className="nav-item mx-0 mx-sm-3 mx-md-4 mx-lg-5 "
                key={index}
              >
                <a className="nav-link text-dark fs-5" href={link.path}>
                  {t(link.name)}
                </a>
              </li>
            ))}
          </ul>
          <NavLink to="dashboard/series/create" className="btn btn-info">
            {t("createOwnQuiz")}
          </NavLink>
        </div>
      </div>
    </nav>
  );
}
