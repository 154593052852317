//
// export const generateUUID = () => {
//   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
//     const r = Math.random() * 16 | 0;
//     const v = c === 'x' ? r : ((r & 0x3) | 0x8);
//     return v.toString(16);
//   });
// }


import { useLocation } from "react-router-dom";
import { ENCRYPTION_KEY } from "./constants";
import { useEffect } from "react";
const CryptoJS = require('crypto-js');

export const encryptWithAES = (text) => {
  return CryptoJS.AES.encrypt(text, ENCRYPTION_KEY).toString();
};

export const decryptWithAES = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export function getUniqueArrayBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export function useLocationEffect(callback) {
  const location = useLocation();

  useEffect(() => {
    callback(location);
  }, [location, callback]);
}

