import React, { useState, useEffect, useContext } from 'react'
import { availableLanguages } from "../i18nextConf";
import { getSingleDoc, updateSingleDoc, uploadImage } from "../firebase";
import { useTranslation } from "react-i18next";
import ContentLoading from "../components/ContentLoading";
import { useNavigate, useParams } from "react-router-dom";
import { emptyQuestionObject } from "../utils/constants";
import { v4 } from 'uuid';
import { UserContext } from '../contexts/UserContext';



export default function CreateQuiz() {
  const { user } = useContext(UserContext)
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const finalData = {
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const [formData, setFormData] = useState({
    icon: '🐟',
    imageUrl: '',
    title: {
      en: '',
      fr: ''
    },
    questions: [],
  });

  const [questions, setQuestions] = useState([]);

  // Get data
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await getSingleDoc('series', id);
      setFormData({ ...data });
      setQuestions(data.questions);
      setIsLoading(false);
    })();
  }, [id]);




  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // upload image url
    if (imageFile) {
      finalData.imageUrl = await uploadImage('quiz', imageFile);
    }
    // add an id to the quiz
    finalData.id = v4();

    // add icon
    finalData.icon = formData.icon;
    // add title
    finalData.title = formData.title;

    finalData.difficulty = formData.difficulty;
    finalData.available = formData.available;

    // upload images for questions
    const tempQuestions = [...questions];
    for (let i = 0; i < tempQuestions.length; i++) {
      const question = tempQuestions[i];
      question.id = v4();
      question.correct = Number(question.correct);

      if (question.image) {
        question.imageUrl = await uploadImage('quiz', question.image);
        delete question.image;
      }
    }
    finalData.questions = tempQuestions;

    // console.log({finalData})


    updateSingleDoc('series', id, finalData)
      .then(() => {
        console.log('quiz updated');
        navigate(`/dashboard/series/${id}`);
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        setIsLoading(false);
      });

  }

  const handleChange = (event) => {
    const { value, dataset } = event.target;
    let localValue = value;

    if (["number", "range"].includes(event.target.type)) {
      localValue = Number(localValue)
    }

    if (event.target.type === "checkbox") {
      localValue = event.target.checked
    }

    setFormData({
      ...formData,
      [dataset.field]: localValue
    });
  }


  const handleChangeMultiLanguage = (event) => {
    const { value, dataset } = event.target;
    const { field, lang } = dataset;

    setFormData({
      ...formData,
      [field]: {
        ...formData[field],
        [lang]: value
      }
    });
  }

  const handleQuestionInput = (event) => {
    const { value, dataset } = event.target;
    const { field, index, lang } = dataset;

    const tempQuestions = [...questions];
    if (lang) {
      tempQuestions[index][field][lang] = value;
    } else {
      if (field === 'image') {
        tempQuestions[index][field] = event.target.files[0];
      } else {
        tempQuestions[index][field] = value;
      }
    }
    setQuestions(tempQuestions);
  }

  const handleAnswerInput = (event) => {
    const { value, dataset } = event.target;
    const { index, lang, answerIndex } = dataset;

    const tempQuestions = [...questions];
    tempQuestions[index].answers[answerIndex][lang] = value;

    setQuestions(tempQuestions);
  }

  const handleRemoveQuestion = (index) => {
    const confirmed = window.confirm(t('deleteQuestionConfirmation'));
    if (!confirmed) {
      return;
    }
    const tempQuestions = [...questions];
    tempQuestions.splice(index, 1);
    setQuestions(tempQuestions);
  }




  return (
    <div>
      <h1>{t('updateQuiz')}</h1>
      {isLoading ? <ContentLoading overlay /> : null}
      <form onSubmit={handleSubmit}>

        <div className="row">
          <div className="form-group">
            <img src={formData.imageUrl} className="img-thumbnail rounded-3 img-fluid d-block my-3" width="100" alt="" />
            <label htmlFor="imageUrl">{t('image')}</label>
            <input onChange={(event) => setImageFile(event.target.files[0])} type="file" name="imageUrl" className="form-control" id="imageUrl" placeholder="imageUrl" />
          </div>
        </div>

        <div className="row">
          <div className="form-group">
            <label htmlFor="icon">{t('icon')}</label>
            <input required value={formData.icon} onInput={handleChange} data-field="icon" type="text" name="icon" className="form-control" id="icon" placeholder="Icon" />
          </div>
        </div>



        <div className="row">
          {
            availableLanguages.map(language => (
              <div className="col-md-6 mb-4" key={language.shortCode}>
                <div className="form-group">
                  <label htmlFor={`title-${language.shortCode}`}>{t('title')} {language.shortCode}</label>
                  <input required value={formData.title[language.shortCode]} onInput={handleChangeMultiLanguage} data-field={`title`} data-lang={language.shortCode} type="text" name={`${language.shortCode}`} className="form-control" id={`title-${language.shortCode}`} placeholder={`Title in ${language.shortCode}`} />
                </div>
              </div>
            ))
          }
        </div>


        <div className="row my-3">
          <div className="col-12">
            <label className="" htmlFor="difficulty">{t('difficulty')} ({formData.difficulty})</label>
            <input onChange={handleChange} className="form-range" value={formData.difficulty} min="1" max="10" step="1" type="range" data-field={`difficulty`} name="difficulty" id="difficulty" />
          </div>
        </div>



        <div className="row my-3">
          <div className="col-12">
            <div className="form-check form-switch">
              <label className="form-check-label" htmlFor={`available`}>{t('available')}</label>
              <input disabled={user.role !== 'admin'} onChange={handleChange} className="form-check-input" checked={formData[`available`]} data-field={`available`} type="checkbox" name={`available`} id={`available`} />
              <div className="form-text">{t('availableNote')}</div>
            </div>
          </div>
        </div>


        {
          questions.length ? questions.map((question, questionIndex) => {
            return (
              <div className="card my-4" key={questionIndex}>

                <div className="card-body bg-primary bg-opacity-25">
                  <div className="text-end mb-4">
                    <button
                      onClick={() => handleRemoveQuestion(questionIndex)}
                      className="btn btn-danger text-light"
                    >
                      {t('removeQuestion')}
                    </button>
                  </div>


                  {/* QUESTION */}
                  <div className="row">
                    {
                      availableLanguages.map((language, index) => (
                        <div className="col-md-6 mb-4" key={index}>
                          <div className="form-group">
                            <label htmlFor={`question-${questionIndex}-${language.shortCode}`}>{t('question')} {language.shortCode}</label>
                            <input required value={question.question[language.shortCode]} onChange={handleQuestionInput} data-field="question" data-lang={language.shortCode} data-index={questionIndex} type="text" name={`question-${questionIndex}-${language.shortCode}`} className="form-control" id={`question-${questionIndex}-${language.shortCode}`} placeholder={`question in ${language.shortCode}`} />
                          </div>
                        </div>
                      ))
                    }
                  </div>



                  {/* description */}
                  <div className="row">
                    {
                      availableLanguages.map((language, index) => (
                        <div className="col-md-6 mb-4" key={index}>
                          <div className="form-group">
                            <label htmlFor={`description-${questionIndex}-${language.shortCode}`}>{t('description')} {language.shortCode}</label>
                            <input required value={question.description[language.shortCode]} onChange={handleQuestionInput} data-field="description" data-lang={language.shortCode} data-index={questionIndex} type="text" name={`description-${questionIndex}-${language.shortCode}`} className="form-control" id={`description-${questionIndex}-${language.shortCode}`} placeholder={`${t('description')} in ${language.shortCode}`} />
                          </div>
                        </div>
                      ))
                    }
                  </div>



                  {/* ANSWERS */}
                  {
                    question.answers.map((answer, answerIndex) => {
                      return (
                        <div key={answerIndex}>
                          <div className="row">
                            {
                              availableLanguages.map((language, index) => (
                                <div className="col-md-6 mb-4" key={index}>
                                  <div className="form-group">
                                    <label htmlFor={`answer-${questionIndex}-${answerIndex}-${language.shortCode}`}>{t('answer')} {answerIndex + 1} in {language.shortCode}</label>
                                    <input required value={answer[language.shortCode]} onChange={handleAnswerInput} data-field="answer" data-lang={language.shortCode} data-index={questionIndex} data-answer-index={answerIndex} type="text" className="form-control" id={`answer-${questionIndex}-${answerIndex}-${language.shortCode}`} placeholder={`${t('answer')} in ${language.shortCode}`} />
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      )
                    }
                    )
                  }



                  {/* CORRECT ANSWER */}
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <div className="form-group">
                        <label htmlFor={`correct-${questionIndex}`}>{t('correctAnswer')}</label>
                        <input required value={question.correct} type="number" min={1} max={question.answers.length} onInput={handleQuestionInput} data-field="correct" data-index={questionIndex} name={`correct-${questionIndex}`} className="form-control" id={`correct-${questionIndex}`} placeholder={t('correctAnswer')} />
                      </div>
                    </div>
                  </div>



                  {/* Question Image */}
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <img src={question.imageUrl} className="img-thumbnail rounded-3 img-fluid d-block my-3" width="100" alt="" />
                      <div className="form-group">
                        <label htmlFor={`image-${questionIndex}`}>{t('image')}</label>
                        <input type="file" onChange={handleQuestionInput} data-field="image" data-index={questionIndex} name={`image-${questionIndex}`} className="form-control" id={`image-${questionIndex}`} placeholder={t('image')} />
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            )
          }) : <></>
        }

        <button
          onClick={() => setQuestions(prevState => [...prevState, { ...emptyQuestionObject() }])}
          type="button" className="btn btn-outline-success px-4 ">+ {t('addQuestion')}</button>


        <div className="text-end mt-5">
          <button type="submit" className="btn btn-primary px-4 "> {t('submit')}</button>
        </div>
        <div style={{
          width: "150px",
          height: "100px",
          borderRadius: '10px',
          bottom: "40px",
          right: "40px",

        }}>
        </div>
      </form>
    </div>
  )
}
