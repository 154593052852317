import React from "react";
import cta1 from "../assets/images/cta-1.png";
import cta2 from "../assets/images/cta-2.png";
import AppStoreButton from "./AppStoreButton";
import PlayStoreButton from "./PlayStoreButton";
import { useTranslation } from "react-i18next";

export default function CallToAction() {
  const { t } = useTranslation();
  return (
    <section id="cta" className="container ">
      <div
        className="main-bg position-relative overflow-hidden"
        style={{
          borderRadius: "20px",
        }}
      >
        <div className="row">
          <div className="col-md-7 d-flex flex-column justify-content-between py-5">
            <p className="ms-md-5 fs-1 fw-bold text-center text-md-start">
              {t("downloadBioquizz")}
            </p>

            <div className="ps-5 ">
              <div className="rowToCol">
                <AppStoreButton />
                <PlayStoreButton />
              </div>
            </div>
          </div>
          <div className="col-md-5 ">
            <div
              className="position-relative"
              style={{
                height: "300px",
              }}
            >
              <img
                className="img-fluid"
                src={cta2}
                alt="cta"
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "40%",
                  transform: "translateX(-50%)",
                }}
              />

              <img
                className="img-fluid"
                src={cta1}
                alt="cta"
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "60%",
                  transform: "translateX(-50%)",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
