import { useEffect, useState } from "react";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import SideBarLayout from "./layouts/SideBarLayout";
import LanguageSelector from "./components/LanguageSelector";
import {
  adminRoutes,
  defaultRoutes,
  userRoutes,
  visitorRoutes,
} from "./routes/routes";
import RouteGuard from "./routes/RouteGuard";
import Error404 from "./pages/Error404";
import { getUniqueArrayBy } from "./utils/helpers";
import ReactGA, { ga } from "react-ga";
import { firebaseConfig } from "./firebase";
import RouteChangeTracker from "./utils/RouteChangeTracker";

const usePageViews = () => {
  let location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      ReactGA.initialize(firebaseConfig.measurementId);
      setInitialized(true);
    }
    ga("set", "page", location.pathname);
    ga("send", "pageview");
  }, [initialized, location]);
};

export default function App() {
  usePageViews();
  useEffect(() => {
    // Create Admin User
    // (async () => {
    //   const user = await registerWithEmailAndPassword('Admin', 'admin1@wtfish.com', 'admin1@wtfish.com', 'local', 'admin');
    //   console.log(user);
    // })()
  }, []);

  return (
    <>
      <RouteChangeTracker />
      <LanguageSelector />
      <Routes>
        <Route element={<RouteGuard />}>
          <Route
            element={
              <>
                <Outlet />
              </>
            }
          >
            {defaultRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.component}
                  index={route.isIndex}
                />
              );
            })}
            {visitorRoutes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.component}
                />
              );
            })}
          </Route>

          <Route
            element={
              <SideBarLayout>
                <Outlet />
              </SideBarLayout>
            }
          >
            {getUniqueArrayBy([...userRoutes, ...adminRoutes], "path").map(
              (route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.component}
                  />
                );
              }
            )}
          </Route>
        </Route>

        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}
