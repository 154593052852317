import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

const SerieCard = ({ serie, currentLanguage }) => {
  const { user } = useContext(UserContext)
  const isAdmin = user.role === 'admin'
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4" key={serie.id}>
      <div className="card h-100">
        <img src={serie.imageUrl} className="card-img-top bg-primary bg-opacity-50 img-cover" style={{ height: '250px' }} alt={serie.id} />
        <div className="card-body">
          <h5 className="card-title">{serie?.icon} {serie?.title[currentLanguage]}</h5>
          <NavLink to={isAdmin ? `/dashboard/series/${serie.id}` : `${serie.id}`} className=" stretched-link" />

        </div>
      </div>
    </div>
  );
}


export default SerieCard;