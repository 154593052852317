export default function TipeeeButton() {


  return (
    <a
      rel="noreferrer"
      target="_blank"
      href="https://en.tipeee.com/what-the-fish"
      className="my-auto"
      style={{
        border: "1px solid #ccc",
        backgroundColor: "#d64858",
        borderRadius: "8px",
        padding: "15px 15px",
        // paddingBottom: "40px",
        // marginBottom: "40px",
        display: "flex",
        justifyContent: "center",
        maxWidth: "200px",
        gap: "15px",
        textDecoration: "none",
        color: "#fff",
      }}
    >

      <span
        style={{
          fontSize: "26px",
          lineHeight: "26px",
          fontWeight: "500"
        }}
      >
        Tipeee!
      </span>

    </a>
  );
}
