// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { addDoc, setDoc, collection, doc, getDoc, getDocs, getFirestore, query, where, deleteDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 } from 'uuid';

// import { getAnalytics } from "firebase/analytics";


export const firebaseConfig = {
  apiKey: "AIzaSyCWR_sfQI0180e8LVYoAxBFDW9RZ3Ic5E8",
  authDomain: "whatthefish-e1286.firebaseapp.com",
  projectId: "whatthefish-e1286",
  storageBucket: "whatthefish-e1286.appspot.com",
  messagingSenderId: "1055224385475",
  appId: "1:1055224385475:web:758a403cc471b20c29f776",
  measurementId: "G-RNLMM2PB6Y"
};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// const analytics = getAnalytics(app);


const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("id", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "players"), {
        id: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    throw err?.code;
  }
};
const registerWithEmailAndPassword = async (name, email, password, authProvider = "dashboard", role = 'user') => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const { uid } = res.user;

    const thisUserDoc = doc(db, "players", uid);
    const newUserDate = {
      id: uid,
      email,
      firstName: name,
      lastName: '',
      score: 0,
      authProvider,
      role,
      imageUrl: '',
      createdAt: (new Date()).getTime()
    };

    await setDoc(thisUserDoc, newUserDate);
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    throw err?.code;
    // alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    // alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};


const getAllCollection = async (path, queryCondition = null) => {
  try {
    const q = queryCondition ? query(collection(db, path), queryCondition) : query(collection(db, path));
    return getDocs(q)
      .then(res => {
        return res.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });

  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const getSingleDoc = async (path, id) => {
  try {
    const docRef = doc(db, path, id);
    const res = await getDoc(docRef);
    return {
      ...res.data(),
      id: res.id,
    };
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const updateSingleDoc = async (path, id, data) => {
  try {
    const docRef = doc(db, path, id);
    return await updateDoc(docRef, data);
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};


const addToCollection = async (path, data) => {
  try {
    // make sure id is matching the id in the collection
    return await setDoc(doc(db, path, data.id), data);

    // return await addDoc(collection(db, path), data);

  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const removeFromCollection = async (path, id) => {
  try {
    return await deleteDoc(doc(db, path, id));
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};


const uploadImage = async (path, file) => {
  try {
    const name = v4();
    const fileRef = ref(storage, `${path}/${name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (err) => reject(err),
        async () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            return resolve(downloadURL);
          });
        }
      );
    });




  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};



export const FIREBASE_ERROR_CODES = {
  "auth/wrong-password": "auth/wrong-password",
  "auth/user-not-found": "auth/user-not-found"
};


export const FIREBASE_ANALYTICS_EVENTS_CATEGORY = {
  user: 'user'
};
export const FIREBASE_ANALYTICS_EVENTS = {
  login: 'login',
  sign_up: 'sign_up',
};


export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getAllCollection,
  getSingleDoc,
  addToCollection,
  removeFromCollection,
  updateSingleDoc,
  uploadImage
};







