import React, { useContext, useEffect, useState } from "react";
import ContentLoading from "../components/ContentLoading";
import { FIREBASE_ANALYTICS_EVENTS, FIREBASE_ANALYTICS_EVENTS_CATEGORY, getSingleDoc, logInWithEmailAndPassword } from "../firebase";
import { UserContext } from "../contexts/UserContext";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

export const Login = () => {
  const { login, user } = useContext(UserContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    setInitialLoading(false);
  }, []);

  if (initialLoading) {
    return <></>;
  }

  if (user.isAdmin) {
    return <Navigate to="/dashboard" replace />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    setError(""); // reset error

    logInWithEmailAndPassword(email, password)
      .then(async (res) => {
        const userData = await getSingleDoc("players", res.user.uid);

        ReactGA.event({
          category: FIREBASE_ANALYTICS_EVENTS_CATEGORY.user,
          action: FIREBASE_ANALYTICS_EVENTS.login,
        });

        if (userData) {
          login(userData);
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setError(t(i18n.exists(err) ? err : 'globalError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className="container"
      style={{
        marginTop: "180px",
      }}
    >
      <div className="d-flex align-items-center justify-content-center flex-column h-100 w-100">
        <h5 className="card-title text-center text-capitalize mb-4">
          {t("login")}
        </h5>
        <div className="card">
          {loading && <ContentLoading />}
          <div
            className="card-body"
            style={{
              width: "400px",
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  {t('emailAddress')}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  placeholder={t('emailAddress')}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  {t('password')}
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder={t('password')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="">
                <button type="submit" className="btn btn-dark w-100">
                  {t('login')}
                </button>
              </div>

              {error && (
                <div className="alert alert-danger mt-3 mb-1 small py-2">
                  <i className="bi bi-exclamation-diamond me-2" />
                  {error}
                </div>
              )}
            </form>
          </div>
        </div>

        <NavLink
          to="/register"
          className="btn btn-link text-dark text-decoration-none"
        >
          <>
            {t("doesntHaveAnAccount")}? {t("register")}
          </>
        </NavLink>
      </div>
    </div>
  );
};
