import React, { useContext, useEffect, useState } from 'react'
import ContentLoading from "../components/ContentLoading";
import i18next from "i18next";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { getSingleDoc, removeFromCollection } from "../firebase";
import { useTranslation } from "react-i18next";
import { UserContext } from '../contexts/UserContext';

export default function SingleSerie() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(UserContext)


  const [currentLanguage, setCurrentLanguage] = useState('fr');

  const [isLoading, setIsLoading] = useState(true);
  const [serie, setSerie] = useState(null);

  useEffect(() => {
    if (!id) return;

    (async () => {
      setIsLoading(true);
      const querySnapshot = await getSingleDoc('series', id);
      setSerie(querySnapshot);
      // console.log(querySnapshot);
      setIsLoading(false);
    })();


  }, [id])

  useEffect(() => {
    i18next.on('languageChanged', () => {
      setCurrentLanguage(i18next.language.split('-')[0]);
    })

    setCurrentLanguage(i18next.language.split('-')[0]);
  }, [])

  const deleteQuiz = async () => {
    const confirmed = window.confirm(t('deleteQuizConfirmation'));
    if (!confirmed) {
      return;
    }

    await removeFromCollection('series', id);
    navigate('/dashboard/series');
  }



  if (isLoading) return <ContentLoading overlay />;

  return (
    <div>
      <img src={serie?.imageUrl} style={{ height: '300px' }} className="img-cover w-100" alt={serie?.title[currentLanguage]} />


      <h1 className="my-3">{serie?.icon} {serie?.title[currentLanguage]}</h1>

      {user.role === 'admin' && <div className="text-end mb-3">
        <NavLink
          to={`edit/`}
          className="btn btn-info me-3" >{t('updateQuiz')}</NavLink>
        <button onClick={deleteQuiz} className="btn btn-danger">{t('removeQuiz')}</button>
      </div>}


      <div className='mb-4'>
        <h5>{t('available')}</h5>
        <p className=" small mb-4 fs-3"><i className={`${serie.available ? 'bi bi-check-circle-fill text-success' : 'bi bi-x-circle-fill text-danger'}`}></i></p>
      </div>

      <div className='mb-4'>
        <h5>{t('difficulty')}</h5>
        <p className=" small mb-4 fs-3">{serie.difficulty}</p>
      </div>

      {
        serie?.questions?.length ? serie.questions.map((question, index) => {
          return (
            <div key={index}>
              <img
                src={question.imageUrl}
                className="img-fluid mt-5 mb-3"
                alt={question.question[currentLanguage]}
                style={{ width: '200px' }}
              />

              <h5>{question.question[currentLanguage]}</h5>
              <p className="text-muted small mb-4">{question.description[currentLanguage]}</p>


              <ul className="list-group" style={{ maxWidth: '500px' }}>
                {
                  question.answers.map((answer, index) => {
                    return (
                      <li key={index} className={`list-group-item ${index + 1 === Number(question.correct) && 'bg-success'}`}>
                        {answer[currentLanguage]}
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          )
        }) : <>{t('noQuestion')}</>
      }
    </div>
  )
}
