import React, { useContext } from 'react'
import { UserContext } from "../contexts/UserContext";
import { useTranslation } from 'react-i18next';

export default function Dashboard() {
  const { user } = useContext(UserContext);
  const { t } = useTranslation()

  return (
    <div>
      <div className='mb-4'><span className='h3 '>{user?.firstName ?? user?.name ?? ''}</span> <span className='text-secondary opacity-50 ms-2'>({user?.email})</span></div>
      <p dangerouslySetInnerHTML={{ __html: t('dashboardWelcome') }} />
    </div>
  )
}
