import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function CreateQuizFab (){

  const { t } = useTranslation();

  return (
    <NavLink to="/dashboard/series/create">
      <button
        style={{
          width: "150px",
          height: "60px",
          borderRadius: '10px',
          position: "fixed",
          bottom: "40px",
          right: "40px",
          zIndex: "999",
        }}
        className="btn btn-warning fw-bold shadow">{t("addQuiz")}</button>
    </NavLink>
  )
}