import React from 'react';
import ReactGA from 'react-ga';
import { useLocationEffect } from './helpers';

const RouteChangeTracker = () => {
    useLocationEffect((location, action) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    });

    return <></>;
};

export default RouteChangeTracker;