import React from "react";
import { useTranslation } from "react-i18next";
import playSVG from "../assets/images/playstore.svg";
import { ANDROID_APP_URL } from "../utils/constants";

export default function PlayStoreButton() {
  const { t } = useTranslation();

  return (
    <a
      rel="noreferrer"
      target="_blank"
      href={ANDROID_APP_URL}
      className="my-auto"
      style={{
        border: "1px solid #ccc",
        backgroundColor: "#000",
        borderRadius: "8px",
        padding: "7px 15px",
        display: "flex",
        maxWidth: "200px",
        //minWidth: "200px",
        gap: "15px",
        textDecoration: "none",
        color: "#fff",
      }}
    >
      <img
        className="img-fluid"
        src={playSVG}
        alt="apple-store"
        style={{
          height: "35px",
        }}
      />
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <span style={{ fontSize: "14px", lineHeight: "14px" }}>
          {t("download")}
        </span>
        <span
          style={{
            fontSize: "26px",
            lineHeight: "26px",
            fontWeight: "500",
          }}
        >
          PlayStore
        </span>
      </span>
    </a>
  );
}
