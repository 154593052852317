import React from "react";
import { EXTERNAL_LINKS, NAVBAR_LINKS, SOCIAL_LINKS } from "../utils/constants";
import logo from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import AppStoreButton from "./AppStoreButton";
import PlayStoreButton from "./PlayStoreButton";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div id="footer" className="pt-5 pb-3">
      <div className="container">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center ">
          <div>
            <ul className="list-unstyled list-inline">
              {NAVBAR_LINKS.map((link, index) => (
                <li className="list-inline-item" key={index}>
                  <a className="nav-link text-dark fs-6" href={link.path}>
                    {t(link.name)}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="rowToCol" style={{
            width: "40%",
       
          }}>
            <AppStoreButton />
            <PlayStoreButton />
          </div>
        </div>

        <ul className="list-unstyled list-inline text-center text-md-start">
          {SOCIAL_LINKS.map((link, index) => (
            <li className="list-inline-item" key={index}>
              <a
                className="nav-link px-2"
                href={link.path}
                rel="noreferrer"
                target="_blank"
              >
                <img src={link.icon} alt="index" />
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Terms and conditions */}
      <div className="container mt-4">
        <div className="d-flex flex-column flex-md-row justify-content-start align-items-center">
          <img height="80px" alt="logo" src={logo} />

          <ul className="list-unstyled  list-inline text-center ms-0 ms-md-5">
            {EXTERNAL_LINKS.map((link, index) => (
              <li
                className="list-inline-item ms-4 d-block d-md-inline-block"
                key={index}
              >
                <a className="nav-link text-dark small" href={link.path}>
                  {t(link.name)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="text-center small mt-3">{t("footerCopyrights")}</div>
    </div>
  );
}
