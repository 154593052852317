import React from "react";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();

  return (
    <section id="about" className="">
      <div className="container">
        <p className="fs-1 fw-bold text-center">{t("aboutHeader")}</p>
        <p className="mt-5 mx-auto text-center text-container">
          {t("aboutSubHeader")}
        </p>
      </div>
    </section>
  );
}
