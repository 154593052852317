import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, matchRoutes } from "react-router-dom";
import { UserContext } from '../contexts/UserContext';
import { allRoutes } from './routes';


const RouteGuard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(UserContext);
  const location = useLocation();

  const matchRoute = matchRoutes(allRoutes, location.pathname)[0] || {}

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <></>
  }

  if (matchRoute?.route?.allowedRoles?.includes(user?.role || 'visitor')) {
    return <Outlet />
  }


  return <Navigate to="/login" replace />;


  // const routeObj = [...defaultRoutes, ...dashboardRoutes].filter(route => route.path === location.pathname)[0] || {}
  // const allowedRolesInThisRoute = routeObj?.allowedRoles || [];
  // if (allowedRolesInThisRoute.includes(user?.role)) {
  // }
  // else {
  //   return <Navigate to="/" replace />;
  // }
}

export default RouteGuard;