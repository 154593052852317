/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { getAllCollection } from "../firebase";
import ContentLoading from "../components/ContentLoading";
import i18next from "i18next";
import SerieCard from "../components/SerieCard";
import { UserContext } from '../contexts/UserContext';
import { where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export default function Series({ type, title }) {
  const { t } = useTranslation()
  const location = useLocation()
  const [currentLanguage, setCurrentLanguage] = useState('fr');
  const { user } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true);
  const [series, setSeries] = useState([]);

  const getSeries = async () => {
    setIsLoading(true)

    let querySnapshot;
    if (type === 'all-series') {
      querySnapshot = await getAllCollection('series')
    }
    else if (type === 'all-users-series') {
      const q = where("createdBy", "==", 'user')
      querySnapshot = await getAllCollection('series', q)
    }
    else if (type === 'to-validate-series') {
      const q = where("available", "==", false)
      querySnapshot = await getAllCollection('series', q)
    }

    else {
      const q = where("creator", "==", user.id)
      querySnapshot = await getAllCollection('series', q)
    }

    setSeries(querySnapshot);

    setIsLoading(false);
  }

  useEffect(() => {
    getSeries();
  }, [location])

  useEffect(() => {
    i18next.on('languageChanged', () => {
      setCurrentLanguage(i18next.language.split('-')[0]);
    })

    setCurrentLanguage(i18next.language.split('-')[0]);
  }, [])


  return (
    <div>
      <h1>{t(title)}</h1>

      {
        isLoading ? <ContentLoading overlay /> :
          <div className="row">
            {series.map(serie => (
              <SerieCard key={serie.id} serie={serie} currentLanguage={currentLanguage} />
            ))}
          </div>
      }

    </div>
  )
}
