const ContentLoading = ({overlay=false}) => {
  return (
    <div style={{
      zIndex: 9999,
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0,0.5)",
      position: overlay ? "fixed" :"absolute",
    }}>
      <div className="spinner-border text-light" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default ContentLoading;