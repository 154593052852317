import React from "react";
import { useTranslation } from "react-i18next";
import arrow from "../assets/images/ios-arrow-left-svgrepo-com.svg";

const FAQs = [
  {
    question: "faq1Question",
    answer: "faq1Answer",
  },
  {
    question: "faq2Question",
    answer: "faq2Answer",
  },
  {
    question: "faq3Question",
    answer: "faq3Answer",
  },
  {
    question: "faq4Question",
    answer: "faq4Answer",
  },
  {
    question: "faq5Question",
    answer: "faq5Answer",
  },
  {
    question: "faq6Question",
    answer: "faq6Answer",
  },
];

export default function FAQ() {
  const { t } = useTranslation();

  return (
    <section id="faq">
      <div className="container">
        <div className="mt-5 ">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {FAQs.map((faq, index) => (
              <div key={index} className="accordion-item bg-transparent">
                <h2 className="accordion-header" id={`flush-heading${index}`}>
                  <button
                    className="accordion-button collapsed bg-transparent"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded="false"
                    aria-controls={`collapse${index}`}
                  >
                    <div className="text-container">
                      <p className="fs-1 fw-bold ">
                        <img src={arrow} alt="down" />
                        {t(faq.question)}
                      </p>
                    </div>
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`flush-heading${index}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <p className="mb-5 mx-auto ">{t(faq.answer)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
