import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import Series from "../pages/Series";
import SingleSerie from "../pages/SingleSerie";
import CreateQuiz from "../pages/CreateQuiz";
import UpdateQuiz from "../pages/UpdateQuiz";
import { Login } from "../pages/Login";
import { Register } from "../pages/Register";
import { getUniqueArrayBy } from "../utils/helpers";

export const visitorRoutes = [
    {
        path: '/login',
        i18n: 'login',
        allowedRoles: ['visitor'],
        component: <Login />,
    },
    {
        path: '/register',
        i18n: 'register',
        allowedRoles: ['visitor'],
        component: <Register />,
    },
]

export const defaultRoutes = [
    {
        path: '/',
        i18n: 'home',
        allowedRoles: ['visitor', 'user', 'admin'],
        component: <Home />,
        isIndex: true
    },

]

const sharedRoutes = [
    {
        path: '/dashboard',
        i18n: 'pages.dashboard',
        allowedRoles: ['user', 'admin'],
        component: <Dashboard />,
        icon: <i className="bi bi-speedometer2 me-2" />,
        sidebar: true
    },
    {
        path: '/dashboard/series/create',
        i18n: 'create-quiz',
        allowedRoles: ['user', 'admin'],
        component: <CreateQuiz />
    },
    {
        path: '/dashboard/series/:id/edit',
        i18n: 'edit-quiz',
        allowedRoles: ['user', 'admin'],
        component: <UpdateQuiz />
    },

]

export const adminRoutes = [
    ...sharedRoutes,
    {
        path: '/dashboard/series',
        i18n: 'pages.series',
        allowedRoles: ['admin'],
        component: <Series title="pages.series" type="all-series" />,
        icon: <i className="bi bi-newspaper me-2" />,
        sidebar: true,
    },
    {
        path: '/dashboard/user-series',
        i18n: 'pages.user-series',
        allowedRoles: ['admin'],
        component: <Series title="pages.user-series" type="all-users-series" />,
        icon: <i className="bi bi-newspaper me-2" />,
        sidebar: true,
    },
    {
        path: '/dashboard/to-validate-series',
        i18n: 'pages.to-validate-series',
        allowedRoles: ['admin'],
        component: <Series title="pages.to-validate-series" type="to-validate-series" />,
        icon: <i className="bi bi-newspaper me-2" />,
        sidebar: true,
    },
    {
        path: '/dashboard/series/:id',
        i18n: 'single-serie',
        allowedRoles: ['admin'],
        component: <SingleSerie />
    },
]

export const userRoutes = [
    ...sharedRoutes,
    {
        path: '/dashboard/my-series/:id',
        i18n: 'single-serie',
        allowedRoles: ['user'],
        component: <SingleSerie />
    },
    {
        path: '/dashboard/my-series',
        i18n: 'pages.my-series',
        component: <Series type="my-series" />,
        allowedRoles: ['user'],
        icon: <i className="bi bi-newspaper me-2" />,
        sidebar: true
    },
    {
        path: '/dashboard/my-series/:id/edit',
        allowedRoles: ['user'],
        i18n: 'edit-quiz',
        component: <UpdateQuiz />
    },
]

export const allRoutes = getUniqueArrayBy([
    ...defaultRoutes,
    ...visitorRoutes,
    ...adminRoutes,
    ...userRoutes
], 'path')