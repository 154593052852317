import facebookImg from "../assets/images/iconmonstr-facebook-5.svg";
import instagramImg from "../assets/images/iconmonstr-instagram-15.svg";


export const USER_COOKIE_KEY = "wtf-user";
export const ENCRYPTION_KEY = "asasfwfaw23623";

export const IOS_APP_URL = "https://apps.apple.com/fr/app/what-the-f-sh/id1639365364";
export const ANDROID_APP_URL = "https://play.google.com/store/apps/details?id=fr.ngware.whatthefish.what_the_fish";

export const NAVBAR_LINKS = [
  {
    name: "home",
    path: "#",
  },
  {
    name: "about",
    path: "#about",
  },
  {
    name: "features",
    path: "#features",
  },
  {
    name: "faq",
    path: "#faq",
  },
];

export const SOCIAL_LINKS = [
  {
    icon: instagramImg,
    path: "https://www.instagram.com/whatthefishapp/",
  },
  {
    icon: facebookImg,
    path: "https://www.facebook.com/profile.php?id=100089605983367",
  },
];

export const EXTERNAL_LINKS = [
  {
    name: "conditionsAndTerms",
    path: "#",
  },
  {
    name: "GDPR",
    path: "#",
  },
  {
    name: "cookiePolicy",
    path: "#",
  },
];



export const emptyQuestionObject = () => {
  return {
    question: {
      en: '',
      fr: ''
    },
    imageUrl: '',
    description: {
      en: '',
      fr: ''
    },
    answers: [
      {
        en: '',
        fr: ''
      },
      {
        en: '',
        fr: ''
      },
      {
        en: '',
        fr: ''
      },
      {
        en: '',
        fr: ''
      },
    ],
    correct: 1,
  }
};


export const passwordMinLength = 8;
export const passwordRegex = `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{${passwordMinLength},}$`